import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { withListItemRole } from '../../../../utils/withListItemRole';

import { theme } from '../../../../styles/2020/theme';

type GridProps = React.HTMLAttributes<HTMLDivElement> &
  StyledAsProps & {
    /* Minimum width for each item */
    min?: React.CSSProperties['width'];
    /* Gap between elements */
    space?: React.CSSProperties['gap'];
  };

/**
 * Displays content in a simple grid.
 */
export const Grid = ({
  min = '250px',
  space = theme.space.m,
  children,
  ...props
}: React.PropsWithChildren<GridProps>) => {
  return (
    <GridContainer min={min} space={space} role="list" {...props}>
      {React.Children.map(children, withListItemRole)}
    </GridContainer>
  );
};

const GridContainer = styled.div<GridProps>(
  ({ space, min }) => css`
    display: grid;
    gap: ${space};

    @supports (width: min(${min}, 100%)) {
      grid-template-columns: repeat(auto-fit, minmax(min(${min}, 100%), 1fr));
    }
  `
);
