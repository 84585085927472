import * as React from 'react';
import { useState } from 'react';

import { Link } from 'stile-shared/src/components/2020/common/Link';
import {
  H3,
  Lead,
  P,
  PageHeading,
  Section,
} from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';
import { Grid } from 'stile-shared/src/components/2020/layout/Grid';
import { CONTACT } from 'stile-shared/src/constants';
import { theme } from 'stile-shared/src/styles/2020/theme';
import { StileApiLeadType } from 'stile-shared/src/types/services';

import { TrialFormUs } from './TrialFormUs';

type ResourceAccessBlockType = React.PropsWithChildren<{
  title: string;
  subtitle: string;
  image: React.ReactNode;
  id: StileApiLeadType;
  redirectUrl: string;
}>;

export function ResourceAccessBlock({
  title,
  subtitle,
  image,
  id,
  children,
  redirectUrl,
}: ResourceAccessBlockType) {
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const onSubmissionSuccess = () => {
    if (window) {
      setSubmissionSuccess(true);
      window.open(redirectUrl, '_blank');
    }
  };

  return (
    <Section>
      <Grid min="450px" space={theme.space.xl}>
        <Column space={theme.space.xl}>
          <div>
            <PageHeading>{title}</PageHeading>
            <Lead as="h2">{subtitle}</Lead>
          </div>
          {image}
          {children}
        </Column>

        {!submissionSuccess && (
          <Column>
            <H3>Download now</H3>
            <P>Fill out the form to get free access to this document.</P>
            <TrialFormUs onSubmissionSuccess={onSubmissionSuccess} leadType={id} />
          </Column>
        )}

        {submissionSuccess && (
          <Column>
            <P>
              Thank you for completing this form. Email us at{' '}
              <Link url={CONTACT.au.email.url}>{CONTACT.au.email.text}</Link> if you have any
              feedback.
            </P>
          </Column>
        )}
      </Grid>
    </Section>
  );
}
